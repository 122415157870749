<template>
  <div class="app-container">
    <div class="table-container">
      <div class="btn__div">
        <AddressManagement @sureRefresh="sureRefresh" ref="add">
          <template>
            <el-button @click.stop="$refs['add'].show()" size="small" type="primary"
              >新增地址</el-button
            >
          </template>
        </AddressManagement>
        <el-button @click="handleDeleteIds" size="small" class="btn__div--mid">批量删除</el-button>
        <el-button @click="handleDeleteAll" size="small" type="text">清除所有地址</el-button>
      </div>

      <el-table
        :data="list"
        @selection-change="handleSelectionChange"
        empty-text="您还未添加收货地址"
        border
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          align="center"
          label="联系人"
          prop="consignee"
          width="200px"
        ></el-table-column>
        <el-table-column align="center" label="电话" prop="mobile" width="216px"></el-table-column>
        <el-table-column align="center" label="地址" prop="address" width="auto">
          <template slot-scope="scope">
            <span class="default" v-if="scope.row.isDefault === '1'">默认</span>
            <span>{{
              scope.row.provinceName +
              scope.row.cityName +
              scope.row.zoneName +
              scope.row.addressDetail
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="220px">
          <template slot-scope="scope">
            <el-button
              @click="handleDelete([scope.row.id], scope.$index)"
              type="text"
              class="table__btn"
              >删除</el-button
            >
            <AddressManagement :id="scope.row.id" :ref="scope.row.id" @sureRefresh="sureRefresh">
              <template>
                <el-button @click="$refs[scope.row.id].show()" type="text" class="table__btn"
                  >编辑</el-button
                >
              </template>
            </AddressManagement>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageNo"
          :page-size="pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { list, handleDeleteIds, deleteAllMyAddress } from '@/api/address/address';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import cloneDeep from 'lodash/cloneDeep';
import AddressManagement from '@/components/AddressManagement';
export default {
  data() {
    const initFilter = {
      name: '', // 参数名称
    };
    return {
      initFilter,
      filter: cloneDeep(initFilter),
      list: [],
      pageNo: 1,
      pageSize: 10,
      orderBy: 'create_date',
      total: 0,
      multipleSelection: [],
      ids: [],
      dialogFormVisible: false, // 是否显示付款弹窗
    };
  },
  components: {
    AddressManagement,
  },
  computed: {
    // 过滤
    data() {
      return pickBy(this.filter, (val) => !!val);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    sureRefresh() {
      // 执行新增/编辑地址操作成功之后刷新页面数据
      this.fetchData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.fetchData();
    },
    fetchData() {
      const listQuery = pick(this, ['pageNo', 'pageSize', 'orderBy', 'data']);
      list(listQuery).then((response) => {
        this.list = response.data.list;
        this.total = response.data.total;
      });
    },
    // 删除
    handleDelete(val, index) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          handleDeleteIds(val).then((response) => {
            this.list.splice(index, 1);
          });
          this.$message({
            type: 'success',
            message: '删除成功！',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 批量删除
    handleDeleteIds() {
      if (this.ids.length > 0) {
        this.$confirm('确认要批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            handleDeleteIds(this.ids).then((response) => {
              this.fetchData();
            });
            this.$message({
              type: 'success',
              message: '删除成功！',
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.$message({
          type: 'warning',
          message: '请先选择需要删除的内容',
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      const ids = [];
      for (let i = 0; i < val.length; i++) {
        ids.push(val[i].id);
      }
      this.ids = ids;
    },
    handleDeleteAll() {
      if (this.total > 0) {
        this.$confirm('确认要清除所有地址吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            deleteAllMyAddress().then((res) => {
              this.fetchData();
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      } else {
        this.$message({
          message: '暂无地址',
          type: 'warning',
        });
      }
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.default {
  width: 36px;
  height: 14px;
  background: #ffe8e8;
  border-radius: 2px;
  color: #AB0033;
  line-height: 14px;
  margin-right: 10px;
  display: inline-block;
}

.btn__div {
  margin-bottom: 10px;
  &--mid {
    margin: 0 10px 0 20px;
    color: #AB0033;
    border: 1px solid #AB0033;
  }
}
/deep/ .el-table {
  .el-table-column--selection {
    .cell {
      padding-left: 20px;
    }
  }
}
.table__btn {
  color: #2d89da;
  font-size: 12px;
  &:first-of-type {
    margin-right: 20px;
  }
}
</style>
